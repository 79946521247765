@import "bourbon/bourbon";
@import "animate";

// Variables
$text-color--main: #222222;
$primary-color: #d6c079;
$bg-secondary: tint($primary-color, 80%);

/*============== 1. General ==============*/
*:focus {
	outline: none!important;
}

body {
	font-family: 'Lato', sans-serif;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	color: $text-color--main;
	line-height: 1.7;
	letter-spacing: 0.8px;
}

a {
	color: $text-color--main;
	transition: color .3s ease;
}

a:hover,
a:focus,
a.active {
	color: $primary-color;
}

h1 {
	font-size: 36px;
}

h2 {
	font-size: 32px;
}

h3 {
	font-size: 28px;
}

h4 {
	font-size: 24px;
}

h5 {
	font-size: 20px;
}

h6 {
	font-size: 16px;
}

ul {
	margin: 0;
	padding: 0;
}

img {
	max-width: 100%;
	height: auto;
}

svg {
	max-width: 100%;
}

a:hover,
a:focus {
	text-decoration: none;
}


/*============== 2. Utilities ==============*/

/* fonts */
.u-h1 {
	font-size: 36px;
}

.u-h2 {
	font-size: 32px;
}

.u-h3 {
	font-size: 28px;
}

.u-h4 {
	font-size: 24px;
}

.u-h5 {
	font-size: 20px;
}

.u-h6 {
	font-size: 16px;
}

.u-text-small {
	font-size: 14px;
}

.u-font-serif {
	font-family: 'Playfair Display', serif;
}

.u-font-italic {
	font-style: italic;
}

.u-weight-700 {
	font-weight: 700;
}

.u-weight-900 {
	font-weight: 900;
}

.u-color-white {
	color: #fff;
}

.u-color-primary {
	color: $primary-color;
}

.u-color-grey {
	color: #575757;
}

/* backgrounds */
.u-bg-secondary {
	background-color: $bg-secondary;
}

.u-bg-white {
	background-color: #fff;
}

.u-bg-primary {
	background-color: tint($primary-color, 50%);
}

.u-bg-cover {
	background-size: cover;
	background-repeat: no-repeat;
}

.u-bg-p-center {
	background-position: center;
}

.u-bg-p-bottom {
	background-position: bottom;
}

/* spacing */
.u-mt-5 {
	margin-top: 5rem;
}

.mt-6 {
	margin-top: 6rem;
}

.mt-7 {
	margin-top: 7rem;
}

.mb-6 {
	margin-bottom: 6rem;
}

.mb-7 {
	margin-bottom: 7rem;
}

.mb-8 {
	margin-bottom: 8rem;
}

.pt-6 {
	padding-top: 6rem;
}

.pt-7 {
	padding-top: 7rem;
}

.pt-8 {
	padding-top: 8rem;
}

.pb-6 {
	padding-bottom: 6rem;
}

.pb-7 {
	padding-bottom: 7rem;
}

.pb-8 {
	padding-bottom: 8rem;
}

/*============== 3. Elements ==============*/
.btn {
	text-transform: uppercase;
    font-size: 14px;
    display: inline-block;
    border-radius: 20px;
	padding: 10px 30px;
	font-weight: 700;
	background-color: $primary-color;
	border: 1px solid $primary-color;
	color: #fff;
	transition: all .3s ease;
}

.btn:hover,
.btn:focus {
	background-color: #fff;
	color: $primary-color;
}

.btn--ghost {
	background-color: transparent;
	color: $primary-color;
}

.btn--ghost:hover,
.btn--ghost:focus {
	background-color: $primary-color;
	color: #fff;
}

.btn--full {
	display: block;
}

.btn--big {
	padding: 13px 70px;
}

.list-bare {
	list-style: none;
}

.short-line {
	width: 80px;
	height: 2px;
	background-color: $primary-color;
	border: 0;
}

.short-line--black {
	background-color: $text-color--main;
}

.icon--grey {
	color: #656565;
	transition: color .3s ease;
}

.icon:hover {
	color: $text-color--main;
}


/*============== 4. Header ==============*/
.header {
	padding: 20px 0;
	position: sticky;
	top: 0;
	background-color: #fff;
	z-index: 999;
	border-bottom: 1px solid #e7e7e7;
}

.header-black {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: 12px 0;
	background-color: rgba(0,0,0,0.4);
	z-index: 999;
}

.header__logo {
	position: relative;
	z-index: 3000;
}

.header__logo span {
	display: block;
	font-size: 30px;
	text-transform: uppercase;
	letter-spacing: 4px;
    color: #fff;
}

.desktop-nav {
	list-style: none;
	align-items: center;
}

.desktop-nav li {
	margin: 0 20px;
	position: relative;
}

.desktop-nav__link {
	text-transform: uppercase;
	font-size: 14px;
}

.header-black .desktop-nav__link {
	color: #fff;
	font-weight: 900;
}

.header-black .desktop-nav__link.active {
	color: $primary-color;
}

.mobile-nav-trigger {
	display: block;
	margin-top: 7px;
	text-align: right;
}

.header-black .mobile-nav-trigger {
	color: #fff;
	z-index: 9999;
	position: relative;
}

.mobile-nav-trigger .icon {
	font-size: 26px;
}

.mobile-nav-list {
	display: none;
	text-align: center;
    list-style: none;
    text-transform: uppercase;
    padding-bottom: 30px;
}

.mobile-nav-list li {
	border-bottom: 1px solid #272727;
    width: 80%;
    max-width: 400px;
    margin: 0 auto;
    padding: 10px 0;
}

.mobile.lang-selector li:last-of-type {
	border-bottom: 0;
}

.header-black .mobile-nav-list {
    background-color: black;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 100px 0 40px;
    z-index: 2000;
}

.header-black .mobile-nav-list li a {
	color: #fff;
}

/*============== 5. Hero ==============*/


@function multiply-px($value1) {
	@return $value1 * 48px / 1px;
}

.hero {
	padding: 110px 0;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
}

.hero--v2 {
	padding: 110px 0;
	position: relative;
}

.hero--v2:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #000;
	opacity: 0.45;
}

.hero--slide {
	height: 500px;
}

.hero--sm {
	padding: 60px 0;
}

.hero--md {
	padding: 100px 0;
}

.hero--md--v2 {
	padding: 120px 0 100px;
}

.hero__title {
	font-size: 42px;
	font-weight: 700;
	margin-bottom: 20px;
}

.hero__subtitle {
	font-weight: 700;
}

@media(min-width: 992px) {
	.hero {
		padding: 180px 0;
	}

	// .hero--v2 {
	// 	padding: 300px 0 220px;
	// }

	.hero--slide {
		height: 80vh;
	}

	.hero--slide--v2 {
		padding: 260px 0 220px;
	}

	.hero--sm {
		padding: 80px 0;
	}

	.hero--md {
		padding: 120px 0;
	}

	.hero--md--v2 {
		padding: 200px 0 120px;
	}

	.hero__title {
		font-size: 58px;
	}

	.hero__subtitle {
		font-size: 18px;
	}
}

// TODO: fix
@media (max-width: 991px) {
	.hero {
		padding-top: 200px;
	}
}

.hero-slider .slick-dots {
	position: absolute;
	bottom: 40px;
	width: 100%;
	text-align: center;
}

.hero-slider .slick-dots li {
	display: inline-block;
	margin: 0 4px;
}

.hero-slider .slick-dots li button {
	border: 0!important;
	border-radius: 50%;
	background-color: rgba(255,255,255,0.5)!important;
	cursor: pointer;
	height: 14px;
	width: 14px;
	padding: 0;
	font-size: 0;
}

.img-slider .slick-dots li.slick-active button {
	background-color: #fff!important;
}

.img-slider .slick-dots {
	position: absolute;
	bottom: 20px;
	width: 100%;
	text-align: center;
}

.img-slider .slick-dots li {
	display: inline-block;
	margin: 0 4px;
}

.img-slider .slick-dots li button {
	border: 0!important;
	border-radius: 50%;
	background-color: rgba(255,255,255,0.5)!important;
	cursor: pointer;
	height: 14px;
	width: 14px;
	padding: 0;
	font-size: 0;
}

/*============== 6. Story ==============*/
.story-block__upper-title {
	color: $primary-color;
	font-size: 14px;
	text-transform: uppercase;
	margin-bottom: 10px;
	font-weight: 700;
}

.story-block__title {
	font-size: 32px;
	margin-bottom: 50px;
	position: relative;
}

.story-block__title:after {
	content: "";
	position: absolute;
	bottom: -18px;
	left: 0;
	width: 80px;
	height: 2px;
	background-color: $primary-color;
}

.story-block p {
	letter-spacing: 0.3px;
}

@media(min-width: 992px) {
	.story-block__title {
		font-size: 36px;
	}
}

/*============== 7. Slider ==============*/
.slider-section {
	position: relative;
	padding: 60px 0 90px;
}

.slider-section__illustration {
	position: absolute;
	top: -175px;
	right: 0;
	z-index: -1;
	opacity: 0.5;
}

.slider-item {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 250px;
	position: relative;
}

.slider-item span {
	display: inline-block;
	width: 85%;
    max-width: 200px;
	background-color: $bg-secondary;
	font-size: 14px;
    padding: 10px;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    color: #575757;
    font-weight: 700;
}

.slider-item--text-sm {
	padding: 8px;
	line-height: 1.3;
}

.slider-section mark {
	background-color: transparent;
	color: #575757;
	font-style: italic;
	display: block;
	font-weight: 500;
}

.slider-section .slick-list {
	height: 280px;
}

.slider-section .slider-arrow {
	position: absolute;
	top: 100%;
	cursor: pointer;
	width: 60px;
}

.slider-section .slider-arrow svg path {
	stroke: $primary-color;
	stroke-width: 2px;
}

.slider-section .slider-arrow--prev {
	left: calc(50% - 60px);
}

.slider-section .slider-arrow--next {
	right: calc(50% - 60px);
}

@media(min-width: 1270px) {
	.slider-section .slider-arrow {
		top: calc(50% - 27px);
		width: auto;
	}

	.slider-section .slider-arrow--prev {
		left: -60px;
	}
	
	.slider-section .slider-arrow--next {
		right: -60px;
	}
}

/*============== 8. Menu ==============*/
.menu ul {
	list-style: none;
}

.menu-nav li {
	text-transform: uppercase;
}

.menu-nav__link.u-color-primary {
	padding-bottom: 2px;
	border-bottom: 2px solid $primary-color;
}

.menu-tab {
	display: none;
	margin: 50px 0;
}

.menu-tab:first-of-type {
	display: block;
}

.menu-tab__img {
	position: absolute;
	top: -100px;
	right: 0;
	opacity: 0.5;
	z-index: -1;
}

@media(min-width: 768px) {
	.menu-list {
		width: 50%;
	}
}

.menu-list-item {
	margin-bottom: 18px;
}

.menu-list-item__title {
	font-weight: 700;
	font-size: 20px;
	position: relative;
}

.menu-list-item sup {
	color: #575757;
    margin-left: 2px;
    font-family: "Lato";
    font-size: 12px;
    font-weight: 400;
}

.menu-list-item__desc {
	color: #575757;
	max-width: 80%;
	font-size: 14px;
}

.menu-list-item__desc,
.menu-list-item__price {
  flex: 1 0 auto;
}

.menu-list-item__dots {
  flex: 0 1 auto;
}

.menu-list-item__dots::before {
    display: block;
    overflow: hidden;
    content: "...........................................................................................................................";
    height: 2em;
    padding: 0 6px;
    word-break: break-word;
	float: right;
	opacity: .3;
}

/*============== 9. Testimonials ==============*/
.testimonial__quote {
	font-size: 22px;
}

.testimonial__person {
	font-size: 18px;
	font-style: italic;
}

.testimonials .slick-dots {
	margin-top: 20px;
}

.testimonials .slick-dots li {
	display: inline-block;
	margin: 0 4px;
}

.testimonials .slick-dots li button {
	background: transparent;
	border-radius: 50%;
    height: 14px;
    width: 14px;
    font-size: 0;
    border: 1px solid $text-color--main;
    padding: 0;
}

.testimonials .slick-dots li.slick-active button {
	background: $text-color--main;
}

.testimonials-container {
	position: relative;
}

.testimonials-container::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	opacity: 0.6;
}

/*============== 10. Article Card ==============*/
.article-card {
	display: block;
	height: 100%;
	background-color: #fff;
	text-align: center;
	padding-bottom: 10px;
	border: 15px solid #ebefe7;
	margin-bottom: 30px;
}

.article-card--sm {
	height: auto;
}

.article-card__img-wrapper {
	height: 200px;
	width: 100%;
	overflow: hidden;
}

.article-card__img {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 100%;
	width: 100%;
	transition: transform .8s ease;
}

.article-card__date {
	font-size: 14px;
	color: #575757;
}

.article-card:hover .article-card__img {
	transform: scale(1.1);
}

@media(min-width: 768px ) and (max-width: 991px) {
	.article-card__img {
		height: 100px;
	}
}

/*============== 11. Gallery ==============*/
.grid-item,
.grid-sizer {
	width: 100%;
}

.grid-item {
	margin-bottom: 4%;
}

@media(min-width: 576px) {
	.grid-item,
	.grid-sizer {
		width: 46%;
	}

	.gutter-sizer {
		width: 4%;
	}

}

@media(min-width: 768px) {
	.grid-item,
	.grid-sizer {
		width: 31.333%;
	}

	.grid-item {
		margin-bottom: 3%;
	}

	.gutter-sizer {
		width: 3%;
	}
}

/*============== 12. Blog ==============*/
.wysiwyg p {
	margin-bottom: 20px;
	letter-spacing: 0.3px;
	color: #575757;
}

.wysiwyg a {
	text-decoration: underline;
	font-weight: 700;
}

.wysiwyg img {
	margin-bottom: 20px;
}

.post-meta {
	display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-bottom: 40px;
}

.post-meta li {
	padding-right: 10px;
}

.post-meta a {
	text-transform: uppercase;
    font-size: 12px;
    color: #575757;
    padding: 8px 21px;
	background-color: $primary-color;
	border: 1px solid $primary-color;
    color: #fff;
    border-radius: 16px;
	font-weight: 700;
	transition: all .3s ease;
}

.post-meta a:hover,
.post-meta a:focus {
	background-color: #fff;
	color: $primary-color;
}

/*============== 13. Form ==============*/
.form input,
.form select,
.form textarea,
.form button {
	width: 100%;
}

.form-confirmation {
	color: $primary-color;
	text-align: center;
	display: none;
}

.form__field {
	margin-bottom: 20px;
}

.form__field input,
.form__field textarea,
.form__field select {
	background-color: $bg-secondary;
	padding: 10px;
	border: 0;
	border-bottom: 1px solid $primary-color;
	color: #575757;
}

.form__field input[type="submit"],
.form__field button {
	background-color: $primary-color;
	border: 1px solid $primary-color;
	padding: 10px;
	cursor: pointer;
	color: #fff;
	font-weight: 700;
	letter-spacing: 1.5px;
	transition: all .3s ease;
}

.form__field input[type="submit"]:hover,
.form__field input[type="submit"]:focus,
.form__field button:hover,
.form__field button:focus {
	background-color: #fff;
	color: $primary-color;
}

.form__field select {
	height: 50px;
	-webkit-appearance: none;
}

.form__field__error {
	color: #575757;
	font-size: 14px;
	font-style: italic;
	display: none;
}

/*============== 14. Contact Info ==============*/
.contact-info {
	list-style: none;
    height: 100%
}

.contact-info li {
    font-size: 16px;
    padding: 10px;
}

.contact-info li span {
	text-transform: uppercase;
    color: $primary-color;
    display: block;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1.5px;
}

/*============== 15. Footer CTA ==============*/
.footer-cta {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 100px 0;
	position: relative;
}


.footer-cta__title {
	font-size: 42px;
	font-weight: 700;
}

.footer-cta__subtitle {
	font-weight: 700;
	margin-bottom: 30px;
}

.footer-cta::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #000;
	opacity: 0.3;
}

@media(min-width: 992px) {
	.footer-cta__title {
		font-size: 50px;
	}

	.footer-cta__subtitle {
		font-size: 18px;
	}
}

/*============== 16. Footer ==============*/
.footer-widgets {
	padding: 60px 0;
	font-size: 14px;
}

.footer p {
	margin-bottom: 0;
}
.footer__copy {
	font-size: 14px;
}

.footer-widget {
	margin-bottom: 30px;
}

.footer-widget__title {
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 15px;
	color: $text-color--main;
}

.footer-widget,
.footer-widget a {
	color: #656565;
}

.footer-widget a:hover {
	color: $primary-color;
}

.footer-widget ul {
	list-style: none;
}

.footer-widget__social-list li {
	display: inline-block;
	margin-right: 11px;
}

.footer-widget__social-list .icon {
	font-size: 20px;
}

.footer-widget__social-list li:last-of-type {
	margin-right: 0;
}

.hero__social-list a {
	color: white;
}

.hero__social-list a:hover {
	color: $primary-color;
}

.wave{
	position: relative;
	z-index: 100;
  }
  .wave::before{
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 10px;
	background-size: 20px 40px;
	
  }
  .wave::after{
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 15px;
	background-size: 40px 40px;
	
  }

  .wave.white::before {
	background-image:
	  radial-gradient(circle at 10px -15px, transparent 20px, #fff 21px);
  }

  .wave.white::after {
	background-image:
	  radial-gradient(circle at 10px 26px, #fff 20px, transparent 21px);
  }

  .wave.sec::before {
	background-image:
	  radial-gradient(circle at 10px -15px, transparent 20px, $bg-secondary 21px);
  }

  .wave.sec::after {
	background-image:
	  radial-gradient(circle at 10px 26px, $bg-secondary 20px, transparent 21px);
  }
  .rotated {
	transform: rotate(180deg);
  }

  .hr-text {
	line-height: 1em;
	position: relative;
	outline: 0;
	border: 0;
	color: black;
	text-align: center;
	height: 1.5em;
	opacity: .5;
	&:before {
	  content: '';
	  background: linear-gradient(to right, transparent, #818078, transparent);
	  position: absolute;
	  left: 0;
	  top: 50%;
	  width: 100%;
	  height: 1px;
	}
	&:after {
	  content: attr(data-content);
	  position: relative;
	  display: inline-block;
	  color: black;
  
	  padding: 0 .5em;
	  line-height: 1.5em;
	  // this is really the only tricky part, you need to specify the background color of the container element...
	  color: #818078;
	  background-color: #fff;
	}
  }
  .v-hide {
	  visibility: hidden;
  }

  .top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	margin: 0;
	z-index: 5000;
}

  .global-panel, .page-panel {
	background-color: saturate(lighten($primary-color, 10%), 5%);
	padding: 10px;
	text-align: center;
	border-bottom: 1px solid darken($primary-color, 20%);

	a {
		color: #76204D;
		font-weight: bold;
		text-decoration: underline;
		&:hover {
			color: $text-color--main;
			text-decoration: none;
		}
	}
  }

  .new-menu {
	position: absolute;
	top: -10px;
	right: -14px;
	
  }

  .new-menu:lang(nl)::after  {
	content: "nieuw";
  }

  .new-menu:lang(fr)::after  {
	content: "nouveau";
  }

  .new-menu:lang(en)::after  {
	content: "new";
  }

  .new-menu::after {
	color: white;
	display: block;
	background-color: red;
	border-radius: 2px;
	transform: rotate(15deg);
	font-size: 9px;
	letter-spacing: 0;
	font-weight: 700;
	padding: 0 2px;
  }

.room {
	padding: 30px 0;
	border-bottom: 1px dashed rgba(#000, .08);
	
}

@media (max-width: 500px) {
	ul.menu-nav {
		display: block !important;
		text-align: center;
	}
	.global-panel {
		font-size: .88em;
		letter-spacing: -.1px;
	}
}

a[href^="tel:"] {
	color: white;
}

.footer a[href^="tel:"] {
	color: #656565;
}

.lang-selector {
	list-style: none;
	.active {
		background-color: $primary-color;
		font-weight: 700;
	}
}

.lang-selected {
	font-weight: 700;
	color: #fff;
}

.en:lang(en), .fr:lang(fr), .nl:lang(nl){
	font-weight: bold;
	color: $primary-color;
}

.emoji {
	vertical-align: middle;
}

// Zoom.js
img[data-action="zoom"] {
	cursor: zoom-in;
  }
  .zoom-img,
  .zoom-img-wrap {
	position: relative;
	z-index: 666;
	transition: all 300ms;
  }
  img.zoom-img {
	cursor: zoom-out;
  }
  .zoom-overlay {
	cursor: zoom-out;
	z-index: 420;
	background: #fff;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	filter: "alpha(opacity=0)";
	opacity: 0;
	transition:      opacity 300ms;
  }
  .zoom-overlay-open .zoom-overlay {
	filter: "alpha(opacity=100)";
	opacity: 1;
  }
  
.capfix {
	// display: inline-block;
	flex: 1 0;
}

.labels {
	// float: right;
}
  
  .label-new {
	text-transform: uppercase;
	color: red;
	border: 1px solid red;
	border-radius: 2px;
	font-size: 6pt;
	letter-spacing: 0;
	font-weight: 600;
	text-align: center;
	font-family: "Lato", sans-serif;
	margin-left: .1rem;
	padding: 1px 2px;
	position: relative;
	top: -2px;

}

.label-deg {
	text-transform: uppercase;
	color: #76204D;
	border: 1px solid #76204D;
	border-radius: 2px;
	font-size: 6pt;
	letter-spacing: 0;
	font-weight: 600;
	text-align: center;
	font-family: "Lato", sans-serif;
	margin-left: .5rem;
	padding: 1px 2px;
	position: relative;
	top: -2px;
}

.label-deg.degustazione {
	color: #690f82;
	border: 1px solid #690f82;
}

.label-deg.tradizione {
	color: #da7563;
	border: 1px solid #da7563;
}

.course {
	text-align: center;
	// border-bottom: 1px dashed #76204D; 
	// line-height: 0;
	color: #76204D;
	margin: 2rem 0;
	font-weight: normal;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 24px;
}

.midline {
	background:#fff; 
    padding:0 10px;
}

.pdt_0 {
	padding-top: 125px !important;
}

.pdt_1 {
	padding-top: 150px !important;
}

.pdt_2 {
	padding-top: 175px !important;
}

.pdt_3 {
	padding-top: 200px !important;
}

.pdt_4 {
	padding-top: 225px !important;
}



.hero-caption {
	position: absolute;
	left: 16px;
	bottom: 16px;
	z-index: 0;
	font-size: small;
}


.el-center {
	margin: auto auto;
}

#newyear ul {
	list-style: circle;
	font-style: italic;
	font-size: 1.1rem;
}

.home-hero-text {
	z-index: 100;
	position: relative;
}

